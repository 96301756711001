import React from 'react'

import { Section, Container, Heading, Text } from 'components'

import LeadCTAButton from 'components/LeadCTAButton'
import FeatureBoxes from 'screens/Home/components/FeatureBoxes'

const FinancialSolutions = ({
  title = 'Sie suchen Ihre optimale Finanzierungslösung?',
  subtitle = 'Wir helfen Ihnen dabei!',
  items = [],
  showCTA = false,
  ctaOtherProps = {},
  ctaText = 'Anfrage starten',
  leadModalType = 'standard_offer',
  ctaButtonID,
  backgroundColor = 'colorBlue2',
}) => {
  return (
    <Section spacing="large" sx={{ backgroundColor }}>
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" sx={{ color: 'colorBlue90' }}>
            {title}
          </Heading.H2>
          {subtitle && (
            <Text
              textAlign="center"
              sx={{ color: 'colorBlue90' }}
              mt={3}
              fontSize={3}
            >
              {subtitle}
            </Text>
          )}
        </Section.Header>

        <Section.Body>
          <FeatureBoxes items={items} />
        </Section.Body>
        {showCTA && (
          <Section.Footer sx={{ textAlign: 'center' }} mt={[6]}>
            <LeadCTAButton
              leadModalType={leadModalType}
              ctaButtonID={ctaButtonID}
              sx={{
                width: ['100%', 'auto'],
                maxWidth: 400,
                mx: ['auto', 0],
              }}
              {...ctaOtherProps}
            >
              {ctaText}
            </LeadCTAButton>
          </Section.Footer>
        )}
      </Container>
    </Section>
  )
}

export default FinancialSolutions
