import React from 'react'

import { Section, Container, Heading, Box } from 'components'

const items = [
  {
    title: 'Was ist ein Immobilien-Teilverkauf?',
    anchor: '#was-ist-ein-immobilien-teilverkauf',
  },
  {
    title: 'Wie funktioniert Teilverkauf bei Häusern?',
    anchor: '#wie-funktioniert-teilverkauf',
  },
  {
    title: 'Meine Auszahlung und Raten berechnen',
    anchor: '#tv-berechnen',
  },
  {
    title: 'Fragen & Antworten zum Teilverkauf',
    anchor: '#faq-teilverkauf',
  },
]
const NavigationalElements = ({}) => {
  return (
    <Section spacing="large" sx={{ backgroundColor: 'colorBlue2' }}>
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" sx={{ color: 'colorBlue90' }}>
            Finanzielle Freiheit beginnt zu Hause - Nutzen Sie Ihr Eigenkapital!
          </Heading.H2>
        </Section.Header>

        <Section.Body>
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              gap: 30,
              justifyContent: 'center',
              marginTop: 50,
            }}
          >
            {items.map(({ title, anchor }) => (
              <Box
                as="a"
                href={anchor}
                key={title}
                sx={{
                  backgroundColor: 'white',
                  padding: '50px 40px',
                  textAlign: 'center',
                  borderRadius: 'large',
                  boxShadow: 'sm',
                  border: '1px solid',
                  borderColor: 'colorBlue10',
                  textDecoration: 'none',
                  '&:hover': { backgroundColor: 'colorBlue10' },
                  transition: 'background-color 0.4s',
                }}
              >
                <Box flexGrow="1">
                  <Heading.H4 color="colorBlue90">{title}</Heading.H4>
                </Box>
              </Box>
            ))}
          </Box>
        </Section.Body>
      </Container>
    </Section>
  )
}

export default NavigationalElements
