import React from 'react'

import { Section, Container, Heading, Text, Box } from 'components'

import LeadCTAButton from 'components/LeadCTAButton'

const LOGOS = [
  { src: '/know-us-from-logos/gewinn_logo.svg', alt: 'Gewinn' },
  { src: '/know-us-from-logos/trend-logo.svg', alt: 'Trend' },
  { src: '/know-us-from-logos/der-standard-logo.svg', alt: 'Der Standard' },
  { src: '/know-us-from-logos/die-presse-logo.svg', alt: 'Die Presse' },
  {
    src: '/know-us-from-logos/salzburger-logo.svg',
    alt: 'Salzburger Nachrichten',
  },
  { src: '/know-us-from-logos/brutkasten-logo.png', alt: 'Brutkasten' },
  {
    src: '/know-us-from-logos/trending-topics-logo.svg',
    alt: 'Trending Topics',
  },
]

const KnowUsFrom = ({
  hideCTA = true,
  textCTA = 'Anfrage starten',
  afterCTAText,
  backgroundColor = 'white',
  leadModalType = 'standard_offer',
  signupReason,
  ctaButtonID,
  textProps = {},
}) => {
  return (
    <Section
      spacing="large"
      sx={{
        backgroundColor,
        position: 'relative',
      }}
    >
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" sx={{ color: 'colorBlue90' }}>
            Bekannt aus
          </Heading.H2>
        </Section.Header>

        <Section.Body
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              marginTop: 20,
              display: 'flex',
              flexWrap: 'wrap',
              gap: ['35px', null, '60px'],
              justifyContent: 'space-around',
            }}
          >
            {LOGOS.map((logo) => (
              <Box
                as="img"
                key={logo.src}
                src={logo.src}
                alt={logo.alt}
                sx={{
                  height: [20, null, 36],
                }}
              />
            ))}
          </Box>
          {!hideCTA && (
            <>
              <LeadCTAButton
                ctaButtonID={ctaButtonID}
                leadModalType={leadModalType}
                signupReason={signupReason}
                sx={{
                  width: ['100%', 'auto'],
                  maxWidth: 600,
                  mx: ['auto', 0],
                  marginTop: [3, 7],
                  textAlign: 'center',
                }}
              >
                {textCTA}
              </LeadCTAButton>
              {afterCTAText && (
                <Text
                  sx={{
                    color: 'colorBlue100',
                    marginTop: [3, 5],
                    textAlign: 'center',
                  }}
                >
                  {afterCTAText}
                </Text>
              )}
            </>
          )}
        </Section.Body>
      </Container>
    </Section>
  )
}

export default KnowUsFrom
