import React, { useState } from 'react'

import { Section, Container, Heading, Box, Text, Button } from 'components'
import LeadCTAButton from 'components/LeadCTAButton'

const items = [
  {
    title: 'Schulden abbezahlen',
    description:
      'Ein Teilverkauf bietet schnelle Liquidität zur Schuldenreduktion, ohne das gesamte Haus aufzugeben. Es ermöglicht, Schuldenlast zu senken, finanzielle Flexibilität zu erhöhen und dabei die Nutzung des Eigentums beizubehalten. Ideal für effiziente Schuldenbewältigung.',
    contentSlug: 'raus-aus-den-schulden-durch-den-immobilienteilverkauf',
  },
  {
    title: 'Eine Ausbildung finanzieren',
    description:
      'Mit einem Immobilien-Teilverkauf kann die Finanzierung eines Studiums einfach umgesetzt werden, indem sie einen Teil ihres Eigenheims verkaufen und das Geld nutzen, um die Studienkosten zu decken.',
    contentSlug:
      'wie-ein-teilverkauf-helfen-kann-das-studium-ihrer-kinder-zu-finanzieren',
  },
  {
    title: 'Ein Lebensereignis finanzieren',
    description:
      'Einen Teil des Eigenheims zu verkaufen, kann eine Möglichkeit sein, eine Hochzeit zu finanzieren oder eine Reise zu finanzieren. Durch den Immobilien-Teilverkauf können Sie schnell zusätzliches Geld erhalten, um Ihre Träume zu verwirklichen.',
    contentSlug:
      'kein-geld-fuer-die-reise-oder-hochzeit-mit-dem-teilverkauf-ist-das',
  },
  {
    title: 'Haussanierung/-umbau finanzieren',
    description:
      'Für die Finanzierung einer Hausrenovierung bietet sich ein Immobilien-Teilverkauf an. Dadurch können Hausbesitzer schnell Mittel beschaffen, um Umbauten zu realisieren, ohne auf einen Sanierungskredit angewiesen zu sein.',
    contentSlug:
      'haus-umbauen-teilverkauf-als-alternative-zum-sanierungskredit',
  },
  {
    title: 'Ihr Kleinunternehmen finanzieren',
    description:
      'Ein Immobilien-Teilverkauf bietet eine schnelle Möglichkeit zur Finanzierung von Kleinunternehmen oder Startups, ohne auf traditionelle Kredite angewiesen zu sein. So können Sie Ihr Unternehmen ohne Verzögerung starten oder erweitern.',
    contentSlug:
      'vom-eigenheim-zum-unternehmer-finanzieren-sie-ihren-startup-traum-durch',
  },
  {
    title: 'Ihre Rente finanzieren',
    description:
      'Durch den Immobilien-Teilverkauf können Sie Ihre Altersvorsorge aufbessern und eine zusätzliche Rente sichern. Verkaufen Sie einen Teil Ihrer Immobilie, um finanzielle Mittel für Ihren Ruhestand zu schaffen, ohne auf Ihr Sparkonto zurückgreifen zu müssen.',
    contentSlug:
      'das-haus-als-altersvorsorge-ruhestand-geniessen-mit-zusaetzlichem-einkommen',
  },
  {
    title: 'Kinder unterstützen',
    description:
      'Durch den Immobilien-Teilverkauf können Eltern finanzielle Unterstützung für ihre Kinder bereitstellen. Ein Teil des Eigenheims wird verkauft, um ihnen zu ermöglichen ihr eigenes Haus zu kaufen und sich die Anzahlung zu leisten.',
    contentSlug: 'vorsorgewohnung',
  },
  {
    title: 'Geschwister ausbezahlen',
    description:
      'Durch den Teilverkauf können Sie Ihre Geschwister ausbezahlen, wenn Sie ein Haus erben. Es wird damit einfacher das Erbe gerecht zu verteilen und Familienkonflikte zu vermeiden.',
    contentSlug:
      'geschwister-auszahlen-erbstreit-vermeiden-teilverkauf-geerbtes-haus',
  },
]
const TVSituations = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  return (
    <Section spacing="large" sx={{ backgroundColor: 'colorBlue2' }}>
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" sx={{ color: 'colorBlue90' }}>
            In welchen finanziellen Situationen hilft mir ein Teilverkauf?
          </Heading.H2>
        </Section.Header>

        <Section.Body>
          <Box>
            <Box
              sx={{
                display: ['block', null, null, 'grid'],
                gridTemplateColumns: '60% 40%',
              }}
            >
              <Box
                sx={{
                  display: ['grid', null, null, 'flex'],
                  gridTemplateColumns: '50% 50%',
                  flexWrap: 'wrap',
                  gap: ['10px', null, null, 30],
                  justifyContent: ['center', null, null, 'start'],
                  marginTop: ['0px', null, null, 50],
                }}
              >
                {items.map(({ title }, index) => (
                  <Box
                    key={title}
                    sx={{
                      height: ['80px', null, null, 'unset'],
                      backgroundColor: 'white',
                      padding: ['0px 10px', null, null, '20px 20px'],
                      textAlign: 'center',
                      borderRadius: 'large',
                      boxShadow: 'sm',
                      border: '1px solid',
                      borderColor: 'colorBlue10',
                      textDecoration: 'none',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      transition: 'background-color 0.4s',
                      ...(currentImageIndex === index
                        ? {
                            backgroundColor: 'colorBlue10',
                          }
                        : { '&:hover': { backgroundColor: 'colorBlue5' } }),
                    }}
                    onClick={() => {
                      if (currentImageIndex !== index)
                        setCurrentImageIndex(index)
                    }}
                  >
                    <Text as="span" color="colorBlue90">
                      {title}
                    </Text>
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  width: ['100%', null, null, 450],
                  height: [300, null, null, '100%'],
                  position: 'relative',
                  marginTop: [20, null, null, 0],
                }}
              >
                {items.map((_, index) => {
                  return (
                    <Box
                      key={Math.random()}
                      as="img"
                      alt={`Illustration für ${items[index].title}`}
                      src={`/tv-situations/${currentImageIndex}.webp`}
                      sx={{
                        width: ['100%', null, null, 450],
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 'large',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'none',
                        ...(currentImageIndex === index && {
                          display: 'block',
                          // animation: 'FadeIn 0.5s',
                        }),
                      }}
                      {...(currentImageIndex !== index && {
                        'aria-hidden': 'true',
                      })}
                    />
                  )
                })}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                gap: 2,
                marginTop: 35,
                backgroundColor: 'colorBlue10',
                padding: 20,
                color: 'colorBlue90',
                borderRadius: 'large',
                minHeight: ['200px', '162px'],
              }}
            >
              <span>{items[currentImageIndex].description}</span>
              {items[currentImageIndex].contentSlug && (
                <Button
                  variant="primary"
                  as="a"
                  href={`ratgeber/${items[currentImageIndex].contentSlug}`}
                  sx={{
                    textAlign: 'center',
                    backgroundColor: 'blue',
                  }}
                >
                  Mehr erfahren
                </Button>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: ['20px', null, null, 40],
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LeadCTAButton
              ctaButtonID="Teilverkauf--Situations"
              leadModalType={'teilverkauf_callback'}
              variant="primary"
              bg="blue"
              sx={{
                width: ['100%', 400],
                maxWidth: 500,
              }}
            >
              Jetzt Beratungsgespräch vereinbaren
            </LeadCTAButton>
          </Box>
        </Section.Body>
      </Container>
    </Section>
  )
}

export default TVSituations
